import { StaticQuery, graphql } from 'gatsby';

import Icon from '../Icon';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

const WelcomeApp = () => {
  const title = 'Hướng dẫn log in và tải apps';
  const mobileAppLink =
    'Truy cập trang <a href="https://www.dolonline.com/app" target="_blank" rel="noopener noreferrer">www.dolonline.com/login</a> TRÊN ĐIỆN THOẠI';
  const desktopAppLink =
    'Truy cập trang <a href="https://www.dolonline.com/app" target="_blank" rel="noopener noreferrer">www.dolonline.com/login</a> ' +
    'TRÊN LAPTOP để sử dụng giao diện chuẩn của Bài thi IELTS trên máy tính';
  const mobileAppDes = [
    'Đăng nhập với Google, sử dụng email đăng ký học',
    'Tải ứng dụng DOL Learning Management'
  ];
  const notes = [
    '<span>Lưu ý #1.</span> Các bạn nhớ thực hiện hai thao tác trên trong cùng một thời gian ngắn để dữ liệu của các bạn được liên kết đúng.',
    '<span>Lưu ý #2.</span> Không Log in bằng Facebook. Bạn cần Log in với Google bằng gmail đã cung cấp cho DOL lúc đăng ký học.',
    '<span>Lưu ý #3.</span> Các bạn đăng nhập vào sẽ start as visitor trước, sau đó buổi đầu đi học các bạn sẽ sign out và sign in lại theo hướng dẫn của giáo viên để kích hoạt tài khoản nha.'
  ];
  return (
    <StaticQuery
      query={graphql`
        query {
          dolAppImage: file(
            relativePath: { eq: "WelcomeDolbie/DolAppImg.png" }
          ) {
            childImageSharp {
              fluid(maxWidth: 676) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={data => (
        <>
          <WelcomeAppContentHeadingMobile>
            <WelcomeAppContentHeadingTitle>
              {title}
            </WelcomeAppContentHeadingTitle>
          </WelcomeAppContentHeadingMobile>
          <WelcomeAppWrapper>
            <WelcomeAppImg>
              <Img
                fluid={data.dolAppImage.childImageSharp.fluid}
                alt="childImageSharp"
              />
            </WelcomeAppImg>
            <WelcomeAppContent>
              <WelcomeAppContentHeading>
                <WelcomeAppContentHeadingTitle>
                  {title}
                </WelcomeAppContentHeadingTitle>
              </WelcomeAppContentHeading>
              <MobileDownLoad>
                <MobileIcon type="MobileIcon" />
                <p dangerouslySetInnerHTML={{ __html: mobileAppLink }} />
              </MobileDownLoad>
              <AppDescription>
                {mobileAppDes.map(des => (
                  <li key={des}>
                    <BenefitIcon type="WelcomListIcon" />
                    <span>{des}</span>
                  </li>
                ))}
              </AppDescription>
              <WebDownLoad>
                <DesktopIcon type="DesktopIcon" />
                <p dangerouslySetInnerHTML={{ __html: desktopAppLink }} />
              </WebDownLoad>
              {notes.map((note, idx) => (
                <Note
                  key={`note${idx}`}
                  dangerouslySetInnerHTML={{ __html: note }}
                />
              ))}
            </WelcomeAppContent>
          </WelcomeAppWrapper>
        </>
      )}
    />
  );
};
const WelcomeAppWrapper = styled.div`
  padding: 1.5em 1em;
  @media screen and (min-width: 769px) {
    padding: 2em 5.5em 0;
    display: flex;
    align-items: center;
  }
`;
const MobileDownLoad = styled.div`
  display: flex;
  padding: 10px 0;
  p {
    font-size: 14px;
    color: #535353;
    a {
      text-decoration: underline;
      color: #535353;
    }
  }
  @media screen and (min-width: 769px) {
    padding: 20px 0;
    p {
      font-size: 18px;
    }
  }
`;
const WebDownLoad = styled.div`
  display: flex;
  padding: 10px 0;
  p {
    font-size: 14px;
    color: #535353;
    a {
      text-decoration: underline;
      color: #535353;
    }
  }
  @media screen and (min-width: 769px) {
    padding: 20px 0;
    p {
      font-size: 18px;
    }
  }
`;
const Note = styled.p`
  padding: 10px;
  font-size: 14px;
  color: #535353;
  span {
    color: ${props => props.theme.brand};
    font-weight: 700;
  }
  @media screen and (min-width: 769px) {
    font-size: 18px;
  }
`;
const WelcomeAppImg = styled.div`{
  padding: 1em 2em;
  width: 100%;
  @media screen and (min-width: 769px) {
    width: 50%;
  }
`;
const AppDescription = styled.ul`
  list-style-type: none;
  margin-left: 30px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.7);
  li {
    display: flex;
    align-items: baseline;
    font-weight: normal;
    font-size: 14px;
    @media screen and (min-width: 769px) {
      font-size: 18px;
    }
  }
`;
const BenefitIcon = styled(Icon)`
  font-size: 10px;
  margin-right: 15px;
`;
const MobileIcon = styled(Icon)`
  margin-right: 15px;
`;
const DesktopIcon = styled(Icon)`
  margin-right: 15px;
`;
const WelcomeAppContent = styled.div`
  padding: 1em;
  @media screen and (min-width: 769px) {
    width: 50%;
    padding: 1em 2em;
  }
`;
const WelcomeAppContentHeading = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    font-size: 2.25em;
    display: block;
  }
`;
const WelcomeAppContentHeadingMobile = styled.div`
  font-weight: 700;
  font-size: 20px;
  display: block;
  text-align: center;
  margin-top: 30px;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const WelcomeAppContentHeadingTitle = styled.span`
  color: #000000;
  font-weight: 700;
  font-size: 1.25em;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
export default WelcomeApp;
