import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import Container from '../components/Container';
import { MainLayout } from '../components/Layout';
import SEO from '../components/SEO';
import Intro from '../components/WelcomeDolbies/Intro';
import OpenDay from '../components/WelcomeDolbies/OpenDay';
import Preparing from '../components/WelcomeDolbies/Preparing';
import WelcomeApp from '../components/WelcomeDolbies/WelcomeApp';
import withI18next from '../components/withI18next';

const WelcomeDolbies = ({ data, pageContext }) => {
  if (!data) return null;
  const { seos, welcomeDolbies } = data.gcms;
  const seo = seos[0];
  const welcomeDolbie = welcomeDolbies[0];
  return (
    <MainLayout>
      {!!seo && (
        <SEO
          title={seo.title}
          keywords={seo.keywords}
          description={seo.description}
          meta={pageContext.meta}
        />
      )}
      <Container>
        <Intro introData={welcomeDolbie.intro} />
      </Container>
      <Container bgc="linear-gradient(180deg, #F9F9F9 0%, rgba(246, 246, 246, 0.2) 100%)">
        <WelcomeApp />
      </Container>
      <Container bgc="#FAFAFA">
        <Preparing
          data={welcomeDolbie.preparing}
          webPic={welcomeDolbie.preparingPicture}
        />
      </Container>
      <Container>
        <OpenDay
          data={welcomeDolbie.openDay}
          mobilePic={welcomeDolbie.openDayPicture}
        />
      </Container>
    </MainLayout>
  );
};
export default withI18next()(WelcomeDolbies);
WelcomeDolbies.propTypes = {
  data: PropTypes.shape({
    gcms: PropTypes.any
  }),
  pageContext: PropTypes.shape({
    meta: PropTypes.any
  })
};

export const query = graphql`
  query fetchWelcomeDolbie {
    gcms {
      seos(where: { path: "welcome-dolbies" }) {
        path
        title
        description
        keywords
      }
      welcomeDolbies(stage: PUBLISHED) {
        preparing {
          ...CTACard
        }
        intro {
          ...CTACard
        }
        openDay {
          ...CTACard
        }
        preparingPicture {
          ...CTACard
        }
        openDayPicture {
          ...CTACard
        }
      }
    }
  }
`;
