import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import welcomeTopBg from '../../images/welcome-top-bg.png';
import welcomeTopMbBg from '../../images/WelcomeDolbie/top-mb-bg.png';

const Intro = ({ introData }) => {
  function createMarkup() {
    return { __html: introData.descriptions[0].html };
  }
  return (
    <WelcomeIntroWrapper>
      <WelcomeIntro>
        <WelcomeHeading>
          <WelcomeIntroHeadingFirst>
            {introData.slogan}
          </WelcomeIntroHeadingFirst>
          <WelcomeIntroHeadingSecond>
            {introData.title}
          </WelcomeIntroHeadingSecond>
        </WelcomeHeading>
        <WelcomeDescription>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </WelcomeDescription>
      </WelcomeIntro>
      <WelcomeImage>
        <ReviewImageMobile src={introData.asset.mobile.url} alt="" />
        <ReviewImageWeb src={introData.asset.wide.url} alt="" />
      </WelcomeImage>
    </WelcomeIntroWrapper>
  );
};
export default Intro;

const WelcomeIntroWrapper = styled.div`
  background-image: url(${welcomeTopMbBg});
  background-repeat: no-repeat;
  background-position: 0 0;
  @media screen and (min-width: 769px) {
    background-image: url(${welcomeTopBg});
    padding: 2em 5.5em 0;
    display: grid;
    grid-template-columns: 5fr 5fr;
    grid-gap: 2em;
    background-position: 0 100px;
  }
`;
const WelcomeIntro = styled.div`
  padding: 5em 2em;
  text-align: center;
  @media screen and (min-width: 769px) {
    text-align: left;
    padding: 2.5em 2.8em 0;
  }
`;
const WelcomeHeading = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (min-width: 769px) {
    text-align: left;
  }
`;
const WelcomeIntroHeadingFirst = styled.h1`
  font-size: 2.34em;
  font-weight: 200;
  line-height: 1.5em;
  margin-right: 10px;
  display: inline-flex;
  @media screen and (min-width: 769px) {
    font-size: 4em;
    font-weight: 200;
  }
`;
const WelcomeIntroHeadingSecond = styled.h1`
  font-size: 2.4em;
  font-weight: 800;
  line-height: 1.5em;
  margin: 0;
  display: inline-flex;
  @media screen and (min-width: 769px) {
    font-size: 4em;
    font-weight: 800;
  }
`;
const WelcomeImage = styled.div`
  @media screen and (min-width: 769px) {
  }
`;
const WelcomeDescription = styled.div`
  font-size: 1em;
  line-height: 2em;
  font-weight: 700;
  @media screen and (min-width: 769px) {
    font-size: 1.5em;
  }
`;
const ReviewImageMobile = styled.img`
  display: block;
  margin: 0 auto;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const ReviewImageWeb = styled.img`
  display: none;
  margin: 0 auto;
  @media screen and (min-width: 769px) {
    display: block;
  }
`;
Intro.propTypes = {
  introData: PropTypes.shape({
    asset: PropTypes.shape({
      mobile: PropTypes.shape({
        url: PropTypes.any
      }),
      wide: PropTypes.shape({
        url: PropTypes.any
      })
    }),
    descriptions: PropTypes.any,
    slogan: PropTypes.any,
    title: PropTypes.any
  })
};
