import DownloadFile from './DownloadFile';
import PropTypes from 'prop-types';
import React from 'react';
import preparingBg from '../../images/WelcomeDolbie/PrepareBG.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Preparing = ({ data, webPic }) => {
  const { t } = useTranslation('welcome-dolbies');
  return (
    <PreparingyWraper>
      <HeadingMobile>{t('preparingTitle')}</HeadingMobile>
      <SubHeadingMobile>{t('preparingSubTitle')}</SubHeadingMobile>
      <PreparingWraperMobile>
        {data.map(dt => (
          <PreparingRow key={dt.slogan}>
            <ImgWrapper>
              {dt.asset && dt.asset.mobile && (
                <ImgView src={dt.asset.mobile.url} alt="mobile-img" />
              )}
            </ImgWrapper>
            <PreparingContent>
              <p>{dt.title}</p>
              {dt.asset.square && (
                <DownloadFile href={dt.asset.square.url} label={dt.slogan} />
              )}
            </PreparingContent>
          </PreparingRow>
        ))}
      </PreparingWraperMobile>
      <PreparingWraperWeb>
        <PreparingContent>
          <Heading>{t('preparingTitle')}</Heading>
          <SubHeading>{t('preparingSubTitle')}</SubHeading>
          {data.map(dt => (
            <PreparingRow key={dt.asset.square.url}>
              {dt.asset.square && (
                <DownloadFile href={dt.asset.square.url} label={dt.slogan} />
              )}
            </PreparingRow>
          ))}
        </PreparingContent>
        <ImgWrapper>
          {webPic.asset.wide && (
            <ImgView src={webPic.asset.wide.url} alt="mobile-img" />
          )}
        </ImgWrapper>
      </PreparingWraperWeb>
      <CircleBG />
    </PreparingyWraper>
  );
};

export default () => null;
Preparing.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }),
  webPic: PropTypes.shape({
    asset: PropTypes.shape({
      wide: PropTypes.shape({
        url: PropTypes.any
      })
    })
  })
};

const PreparingyWraper = styled.div`
  padding: 1.5em;
  font-weight: 700;
  @media screen and (min-width: 769px) {
    padding: 2em 3em;
    position: relative;
  }
`;
const CircleBG = styled.div`
  @media screen and (min-width: 769px) {
    background-image: url(${preparingBg});
    background-repeat: no-repeat;
    width: 190px;
    height: 275px;
    right: -67px;
    top: -80px;
    position: absolute;
  }
`;
const Heading = styled.h1`
  display: none;
  @media screen and (min-width: 769px) {
    font-size: 36px;
    display: block;
  }
`;
const SubHeading = styled.h2`
  display: none;
  @media screen and (min-width: 769px) {
    font-size: 18px;
    font-weight: 300;
    display: block;
  }
`;
const HeadingMobile = styled.h1`
  font-size: 20px;
  text-align: center;
  display: block;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const SubHeadingMobile = styled.h2`
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  display: block;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const PreparingWraperMobile = styled.div`
  display: block;
  padding: 1em;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const PreparingWraperWeb = styled.div`
  display: none;
  padding: 2em 3em;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`;
const PreparingRow = styled.div`
  display: flex;
`;
const ImgWrapper = styled.div`
  width: 50%;
`;
const PreparingContent = styled.div`
  width: 50%;
  padding: 10px;
`;
const ImgView = styled.img`
  width: 100%;
  height: auto;
`;
