import Icon from '../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const OpenDay = ({ data, mobilePic }) => {
  const { t } = useTranslation('welcome-dolbies');
  return (
    <OpenDayWraper>
      <Heading>{t('openDayTitle')}</Heading>
      <SubHeading>{t('openDaySubTitle')}</SubHeading>
      <OpenDayWraperMobile>
        <OpenDayRow>
          <OpenDayContent>
            {data.map(dt => (
              <li key={dt.title}>
                <BenefitIcon type="WelcomListIcon" />
                <span>{dt.title}</span>
              </li>
            ))}
          </OpenDayContent>
          <ImgWrapper>
            {mobilePic.asset && (
              <OpenDayImg src={mobilePic.asset.mobile.url} alt="mobile-img" />
            )}
          </ImgWrapper>
        </OpenDayRow>
      </OpenDayWraperMobile>
      <OpenDayWraperWeb>
        <OpenDayRow>
          {data.map(dt => (
            <OpenDayItem key={dt.title}>
              {dt.asset && (
                <OpenDayImg src={dt.asset.wide.url} alt="mobile-img" />
              )}
              <OpenDayContentWeb>{dt.title}</OpenDayContentWeb>
            </OpenDayItem>
          ))}
        </OpenDayRow>
      </OpenDayWraperWeb>
    </OpenDayWraper>
  );
};

export default OpenDay;

OpenDay.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }),
  mobilePic: PropTypes.shape({
    asset: PropTypes.shape({
      mobile: PropTypes.shape({
        url: PropTypes.any
      })
    })
  })
};
const OpenDayWraper = styled.div`
  padding: 1.5em;
  font-weight: 700;
  @media screen and (min-width: 769px) {
    padding: 2em 3em;
  }
`;
const Heading = styled.h1`
  font-size: 20px;
  text-align: center;
  @media screen and (min-width: 769px) {
    font-size: 36px;
  }
`;
const SubHeading = styled.h2`
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  @media screen and (min-width: 769px) {
    font-size: 18px;
  }
`;
const OpenDayWraperMobile = styled.div`
  display: block;
  padding: 1em;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const OpenDayWraperWeb = styled.div`
  display: none;
  padding: 2em 3em;
  @media screen and (min-width: 769px) {
    display: block;
  }
`;
const OpenDayRow = styled.div`
  display: block;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`;
const OpenDayContent = styled.ul`
  list-style-type: none;
  margin-left: 0;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  li {
    display: flex;
    align-items: baseline;
    font-weight: normal;
  }
`;
const OpenDayItem = styled.div`
  display: block;
  width: 33%;
`;
const OpenDayContentWeb = styled.div`
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 300;
`;
const OpenDayImg = styled.img`
  width: 100%;
  height: auto;
`;
const BenefitIcon = styled(Icon)`
  font-size: 10px;
  margin-right: 15px;
`;
const ImgWrapper = styled.div`
  padding: 15px 10px;
`;
