import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../Icon';

const DownloadFile = ({ label, url, ...rest }) => {
  const { t } = useTranslation('welcome');

  return (
    <>
      <Download
        download
        href={`${url}?dl=true`}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        <DownloadIcon />
        {t(label)}
      </Download>
      <DownloadWeb
        download
        href={`${url}?dl=true`}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {t(label)}
        <DownloadIcon />
      </DownloadWeb>
    </>
  );
};

const DownloadIcon = styled(Icon).attrs({ type: 'DownLoadPdfIcon' })`
  margin-right: 0.5em;
  @media screen and (min-width: 769px) {
  }
`;

const DownloadWeb = styled.a`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #000;
    font-weight: 700;
    font-size: 18px;
    min-width: 380px;
    padding: 10px;
    display: block;
    margin: 10px 0 20px;
    position: relative;
    ${DownloadIcon} {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }
`;
const Download = styled.a`
  color: ${({ theme }) => theme.brand};
  padding: 1em 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
  display: block;
  @media screen and (min-width: 769px) {
    display: none;
  }
`;

DownloadFile.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string.isRequired
};

DownloadFile.defaultProps = {
  label: 'Download PDF'
};

export default DownloadFile;
